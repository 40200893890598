@import './prismjs';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

.drag-handle {
  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.disabled {
  color: 'grey';
}

svg.pointerCursor {
  &:hover {
    transition: transform 0.5s ease;
    -webkit-transform: scale(1.2);
  }
}

.pointerCursor {
  &:hover {
    cursor: pointer;
  }
}

.pointerCursorIcon {
  &:hover {
    cursor: pointer;
  }
}

.published {
  color: #ffffff !important;
  background-color: #66bb6a !important;
}

.hidden {
  color: #ffffff !important;
  background-color: #e57373 !important;
}

.nowrap {
  white-space: nowrap;
}

.robotoFont {
  font-family: 'Roboto';
}
